import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "this-left-panel-is-part-of-the-carbon-ui-shell-a-shell-is-a-collection-of-components-shared-by-all-products-within-a-platform-it-provides-a-common-set-of-interaction-patterns-that-persist-between-and-across-products"
    }}>{`This left panel is part of the Carbon UI shell. A shell is a collection of components shared by all products within a platform. It provides a common set of interaction patterns that persist between and across products.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Behavior</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="UI Shell template" href="https://sketch.cloud/s/EjVmA" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
    </Row>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`The UI shell is made up of three components: The `}<a parentName="p" {...{
        "href": "/components/UI-shell-header/usage"
      }}>{`header`}</a>{`, the left panel, and the `}<a parentName="p" {...{
        "href": "/components/UI-shell-right-panel/usage"
      }}>{`right panel`}</a>{`. All three can be used independently, but the components were designed to work together.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shell UI component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The highest level of navigation. The header can be used on its own for simple products or be used to trigger the left and right panels.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Left panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that is used for a product’s navigation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Right panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that shows additional system level actions or content associated with a system icon in the header.`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.42391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACXklEQVQ4y5WUS2gTURSGbyaxRauJgTSTGScSLaWbklZUfIA7cavgxlAXLqwuXLsQSSQQSRdKFoK4sBjHEBCCWYhgKb4KCiKKpYiKIFiKIVNjYkLzmslc/5mmIbdJS3rg59xz5/Ddc+5jCNnEPo9cZOL5kUnSswmCYPH5fBw853a7OZ7nOZd7kPPyAndp71HrWWnc2je42yLyHvObISPPyPd6vZwkSb0vRind1ksewEw8BB2A/G0aa/pD0Pi6Ob/H4/GLojiGzvyA7WBowWDwZSwWo9FotBYOh7VQKNTSjbYx8rRIJKIFAgHN5XKpADbQrorWjzHAarX6hq5ag25iuq6bPp1OUwApqqTYT8MfZ4D5fP6Vqqq0Xq+rtVpNLxQKXZXL5XTk6YlEQsehNAwY1ACQrVBRlNfNIjQDXCwWuwpQs8pkMkkB1JvVNTpaRnILCGsBSqUSIwNoGCqkTqdTb7bcWSH2cEtAWZap3W7fGFipVDYEto+3AnzRBFaxhyoAXYXDU5GjAqgCWAdIA7DereUPtAdbuzapVIo6HA6Ke2heHegEA/z7r3BtpVh6XC6XH6A1GacuK9msrPxZfphd+v3IHGMuk8nIyJHvT0/LAMYlQZTdPB/fI4jDJmjGdaYFnSJkYCch1vVv9DIhfXCWjjd+erGfHk4OrMXnpYOEfBu9YgbLV+O2HyevO5cmbm9HR8YPgYMsdO67tRh7alu5N2OjudrqHDT78Z3l2dC5XbPDE45J6QhbxKd9F5j4690nZKGikIXnc8z8Fyw0//Y9+bn4i9yZusV8u7n/lOn/A+zvT3LmMoi9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "UI shell components.",
            "title": "UI shell components.",
            "src": "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png",
            "srcSet": ["/static/be299d265c24f9028bcc0646d8bfd634/d6747/shell-usage-1.png 288w", "/static/be299d265c24f9028bcc0646d8bfd634/09548/shell-usage-1.png 576w", "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png 1152w", "/static/be299d265c24f9028bcc0646d8bfd634/c3e47/shell-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">UI shell components</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <p>{`The left panel contains secondary navigation and is positioned below the header and fixed to the left. Both links and sub-menus can be used in the side-nav and may be mixed together.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.81521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAABtklEQVQ4y51SzUrDQBBOm6qUFvQk+gT1Wbx68AGkCl5FPInWForePXkUfAEfoBSkhVD8qYeq4EEQSpsmbZO2abJJ1m9iUqKYtvjBx+7Oznw7MzuCADxlskKwPqS3hNfCtTALjUYj+pKEAtEAz3ozxjlPgKIkSWKpVBLL5bJYqVQS1Wo1Rj61Wu1vsfD+I3+zSPuBpu+NRqMXULIsq2bbNlFijJFtn3xM04xHZRgHk2CqsXm8SjatKV8gO97r9fhvGIZx7guK00pOgctv28UVshlqP+fYDle7XXM4HNrIzIaWSYLj8ThPPshYjCzZFxWbl7dLdGamdUrBuq4zTdNcZOPiyMiGfYF8HMeJzjAA/L2+MJudBIKgiz7OJxjO8BGU7+oL3qf0tRzK5KqqMpTmEgJB9NATxCPi1NEhvB9eJb1PUbo5BHCQRH4Izix5MnuZXcGwv181xoZXMoL+J1jfyAr3mZ3JzyHoxJ8SC1qOT8sXzM8UDEpG3wLBMx4B3BXnKjk8rGj8AbIdIOgTa5Po7we4Owo/PhXtdttbW61WWlGUdfzyWphkozvy6XQ6wlyQZXmmT5TYF9QK6AYdKwJGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Left panel with nested sub-menus.",
            "title": "Left panel with nested sub-menus.",
            "src": "/static/2df1e8225d41343a0ee1245e6b2d3609/fb070/left-panel-usage-1.png",
            "srcSet": ["/static/2df1e8225d41343a0ee1245e6b2d3609/d6747/left-panel-usage-1.png 288w", "/static/2df1e8225d41343a0ee1245e6b2d3609/09548/left-panel-usage-1.png 576w", "/static/2df1e8225d41343a0ee1245e6b2d3609/fb070/left-panel-usage-1.png 1152w", "/static/2df1e8225d41343a0ee1245e6b2d3609/c3e47/left-panel-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Left panel with nested sub-menus.</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <p>{`Use the left panel if there are more than five secondary navigation items, or if you expect a user to switch between secondary items frequently. Sub-menus are denoted with a chevron and expand when clicked, pushing the other items down in the panel. To collapse the sub-menu, the user must again click the menu header in the left panel.`}</p>
    <p>{`The left panel does not support three tiers of navigation. If you have additional content to display beneath a sub-menu, use tabs within the page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      